import {Card, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Switch} from 'antd';
import React, {RefObject} from "react";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../../model/interface/dataStorage/IField";
import {API_FILTER_TYPE} from "../../../../../../../model/constants/ApiConstant";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import Search from "antd/es/input/Search";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {CodeOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import DataStorageService from "../../../../../../../model/service/dataStorage/DataStorageService";
import IRestResource from "../../../../../../../model/interface/api/IRestResource";
import {ActionTypes} from "../../../../../../../model/interface/dataStorage/IAction";
import IconPicker from "../../../../../../shared/IconPicker";
import ButtonTypePicker from "../../../../../../shared/button/ButtonTypePicker";
import {ButtonTypes} from "../../../../../../shared/button/Button";
import FieldContentTypeOptionsEditor, {
    IFieldContentTypeOptions
} from "../../../../content-type/field/optionEditors/FieldContentTypeOptionsEditor";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";

type Mode = "radio" | "select" | "autocomplete" | "auto"

export interface IContentTypeEditorOptions extends IFieldContentTypeOptions {
    contentTypeFullClassName?: string
    contentTypePresenter?: string
    contentTypeMode?: Mode
    contentTypeAutocompleteField?: string
    contentTypeOrderField?:  'ASC' | "DESC"
    contentTypeOrderDirection?: string
    contentTypeAutocompleteMode?: string
    contentTypeAutocompleteMin?: number
    contentTypeCascadeField?: string
    contentTypeAdditionalRows?: IRestResource[]
    contentTypeAllowCreation?: boolean
    contentTypeCreationAction?: string
    contentTypeCreationButtonText?: string
    contentTypeCreationButtonIcon?: string
    contentTypeCreationButtonType?: ButtonTypes
}

interface IProps extends IBaseProps {
    onChange: (options: IFieldOptions, afterCallback?: () => void) => void
    options: IFieldOptions
    formRef: RefObject<FormInstance>
    findServiceByClassName: (name: string) => IRepositoryService,
    field: IField
}

interface IState {
    mode: Mode
}

class FormFieldContentTypeEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            mode: "auto"
        };
    }

    componentDidMount() {
        this.populateMandatoryOptions()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.populateMandatoryOptions()
    }

    populateMandatoryOptions = () => {
        const {field} = this.props
        let update: any = {}
        if ([RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].indexOf(field.type) >= 0 && !this.props.options.multiple) {
            update.multiple = true
        }
        const presenters = this.getService().getPresenterList()
        if (presenters.length === 1 && !this.props.options.contentTypePresenter) {
            update.contentTypePresenter = presenters[0].value
        }
        if (Object.keys(update).length > 0) {
            this.props.onChange({...this.props.options, ...update}, () => this.props.formRef.current?.resetFields(Object.keys(update)))
        }
    }

    getService(): IRepositoryService {
        const {field, findServiceByClassName} = this.props
        if (!field.targetEntity) {
            throw new Error(`Target entity not defined for contentType[${field.contentTypeId}] field[${field.name}]`)
        }

        let service: DataStorageService = findServiceByClassName(field.targetEntity) as DataStorageService
        if (!service) {
            throw new Error('Service for "' + field.targetEntity + '" is missing')
        }

        return service;
    }

    updateMode = (mode: Mode) => {
        this.setState({mode})
    }

    render() {
        const {mode} = this.state
        const {options, history, match} = this.props
        const service = this.getService()
        const contentType = service.getContentType ? service.getContentType() : null
        const presenters = service.getPresenterList()
        const fields = service.getFields()
        const orderFields = fields.filter(field => field.mode === "scalar").map(field => ({
            value: field.uuid,
            label: field.label || field.name
        }))

        return (
            <Card>
                <Form.Item label={"Typ obsahu"}>
                    <Input disabled value={service.getTitle()}/>
                </Form.Item>
                {presenters && (
                    <Form.Item label={"Zobrazení"} name={"contentTypePresenter"} rules={[{required: true}]}>
                        <Select options={presenters}/>
                    </Form.Item>
                )}
                {contentType && (
                    <FieldContentTypeOptionsEditor buildFieldName={(...args) => args} targetContentType={contentType}
                                                   options={options} match={match}
                                                   history={history}/>
                )}

                <Form.Item label={"Řadit dle"} name={"contentTypeOrderField"}>
                    <Select options={orderFields} allowClear={true}/>
                </Form.Item>

                <Form.Item label={"Řadit směr"} name={"contentTypeOrderDirection"} initialValue={'auto'}>
                    <Select allowClear={true}>
                        <Select.Option key={'asc'} value={"ASC"}>
                            <Row justify={"space-between"} align={"middle"}>
                                Vzestupně<SortAscendingOutlined style={{fontSize: 20}}/>
                            </Row>
                        </Select.Option>
                        <Select.Option key={'desc'} value={"DESC"}>
                            <Row justify={"space-between"} align={"middle"}>
                                Sestupně<SortDescendingOutlined style={{fontSize: 20}}/>
                            </Row>
                        </Select.Option>
                    </Select>
                </Form.Item>

                {/*{this.props.options.multiple ? 'multiple' : 'N/A'}*/}
                <Form.Item label={"Typ"} name={"contentTypeMode"} rules={[{required: true}]} initialValue={'auto'}>
                    <Select onChange={this.updateMode}>
                        <Select.Option value={'auto'}>
                            <Row justify={"space-between"} align={"middle"}>
                                Automatický
                                <CodeOutlined style={{fontSize: 'large'}}/>
                            </Row>
                        </Select.Option>

                        <Select.Option value={'select'}>
                            <Row justify={"space-between"} align={"middle"}>
                                SelectBox
                                <Select className={'d-inline-block w-auto'} defaultValue={1} size={"small"}>
                                    <Select.Option value={1}>test</Select.Option>
                                </Select>
                            </Row>
                        </Select.Option>
                        <Select.Option value={'radio'}>
                            <Row justify={"space-between"} align={"middle"}>
                                Přepínač
                                <Radio className={'d-flex align-items-center'}>Test</Radio>
                            </Row>
                        </Select.Option>
                        <Select.Option value={'autocomplete'}>
                            <Row justify={"space-between"} align={"middle"}>
                                Našeptávač
                                <Search size={"small"} placeholder="search" className={'w-auto'}/>
                            </Row>
                        </Select.Option>
                    </Select>
                </Form.Item>
                {mode === "autocomplete" && (
                    <div>
                        {fields && (
                            <Form.Item label={"Hledat podle"} name={"contentTypeAutocompleteField"}
                                       rules={[{required: true}]}>
                                <Select>
                                    {fields.map((field: IField) =>
                                        <Select.Option key={field.id} value={field.name}>
                                            {`${field.label} [${field.name}]`}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item label={"Shoda"} name={"contentTypeAutocompleteMode"} rules={[{required: true}]}
                                   initialValue={API_FILTER_TYPE.LIKE}>
                            <Select>
                                <Select.Option value={API_FILTER_TYPE.LIKE}>{'Uvnitř'}</Select.Option>
                                <Select.Option value={API_FILTER_TYPE.LIKE_BEGINNING}>
                                    {'Začátek'}
                                </Select.Option>
                                <Select.Option
                                    value={API_FILTER_TYPE.EQUAL}>{'Presna hodnota'}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={"Minimum znaků"} name={"contentTypeAutocompleteMin"} initialValue={2}
                                   rules={[{required: true}]}>
                            <InputNumber/>
                        </Form.Item>
                    </div>
                )}
                {contentType && (
                    <Card size={"small"}>
                        <Form.Item label={'Povolit založit nový záznam'} name={'contentTypeAllowCreation'}
                                   valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                        {options.contentTypeAllowCreation && (
                            <div>
                                <Form.Item label={'Akce pro založení'} name={'contentTypeCreationAction'} help={'Pouze akce s povoleným modálním zobrazením a bez přesměrování'}>
                                    <Select>
                                        {contentType.actions.filter(a => a.type === ActionTypes.FORM && a.modal && !a.afterRedirect).map(a => (
                                            <Select.Option value={a.uuid} key={a.uuid}>{a.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={'Text tlačítka pro založení'} name={'contentTypeCreationButtonText'}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Ikona tlačítka založení'} name={'contentTypeCreationButtonIcon'}
                                           initialValue={'ant.PlusOutlined'}>
                                    <IconPicker/>
                                </Form.Item>
                                <Form.Item label={'Typ tlačítka'} name={'contentTypeCreationButtonType'}
                                           initialValue={'default'}>
                                    <ButtonTypePicker className={'w-100'}/>
                                </Form.Item>
                            </div>
                        )}
                    </Card>
                )}

            </Card>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}
export default connect(mapStateToProps)(FormFieldContentTypeEditor)