import React, {RefObject} from "react";
import {Form, FormInstance, Input, Modal, Select, Switch, TreeSelect, Typography} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {IRepositoryServiceList, isVirtual} from "../../../../../redux/selectors/Services";
import {IContentTypeStepProps} from "../ContentTypeConfiguration";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import IField, {
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    POSSIBLE_SCALAR_FIELD_TYPE_CONVERSIONS,
    RELATION_FIELD_TYPE
} from "../../../../../model/interface/dataStorage/IField";
import IScript from "../../../../../model/interface/dataStorage/IScript";
import selectors from "../../../../../redux/selectors";
import TreeStructure, {INode, ITreeStructure} from "../../../../../utils/TreeStructure";
import FormFieldType from "../../form/FormElement/formField/FormFieldType";
import FieldEditor from "../../form/FormElement/optionEditor/FieldEditor";
import ArgumentEditor from "../../../ArgumentEditor";
import ICustomArguments from "../../../../../model/interface/dataStorage/ICustomArguments";
import FieldOptionsEditor from "./FieldOptionsEditor";
import Utils from "../../../../../utils";
import ScriptPicker from "../../../../shared/pickers/ScriptPicker";
import FieldDefaultValuePicker from "../../../../shared/pickers/FieldDefaultValuePicker";
import IFieldType from "../../../../../model/interface/dataStorage/IFieldType";
import {SQLReservedWords} from "../../../../../model/constants/SQLReservedWords";
import FieldPicker from "../../../../shared/pickers/FieldPicker";

interface IProps extends IContentTypeStepProps {
    listRepositoryServices: () => IRepositoryServiceList,
    findServiceByClassName: (className: string) => IRepositoryService,
    scripts: IScript[],
    onFinish: (field?: IField) => void
    field: IField
}

interface IState {
    formValues: { [name: string]: any }
    counter: number,
    argument?: string
}

class ContentTypeFieldModal extends React.Component<IProps, IState> {

    refFieldForm: RefObject<FormInstance> = React.createRef()
    refOptionsForm: RefObject<FormInstance> = React.createRef()

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            formValues: {
                ...props.field,
                options: Array.isArray(props.field.options) ? {} : props.field.options,
                script: props.field.script,
                defaultValue: props.field.type ?
                    FormFieldType.formatToForm(FieldEditor.detectType(props.field), props.field.defaultValue) : undefined
            },
            counter: 0,
        }
    }

    onValuesChange = (_currentValue: any, formValues: any) => {
        const {field} = this.props
        formValues = {...this.state.formValues, ...formValues}
        if (_currentValue['mode']) {
            let type = undefined
            if (formValues['mode'] === field.mode) {
                type = field.type
            }
            this.refFieldForm.current?.setFieldsValue({type})
            formValues['type'] = type
        }
        if (_currentValue['type'] || _currentValue['mode'] || _currentValue['targetEntity']) {
            this.refFieldForm.current?.setFieldsValue({defaultValue: undefined})
            this.setState({
                formValues: {
                    ...formValues,
                    defaultValue: undefined,
                    type: undefined
                }
            }, () => this.setState({formValues}))
            return
        }
        this.setState({formValues})
    }

    onModalConfirm = () => {
        const {onFinish} = this.props
        const {formValues} = this.state
        Promise.all([
            this.refFieldForm.current?.validateFields(),
            this.refOptionsForm.current?.validateFields()
        ]).then(() => {
                let field = {
                    ...this.props.field || {},
                    ...formValues,
                    defaultValue: Utils.parseObjectToIdentifier(formValues.defaultValue, 'id')
                } as IField
                onFinish(field)
            }
        ).catch((error: Error) => {
            console.log('catch error', error)
        })
    }

    buildFieldTypesTree() {
        const {formValues} = this.state
        const {fieldScalarTypes, field} = this.props
        return TreeStructure.build(Object.fromEntries(fieldScalarTypes.map(value =>
            [value.value, {
                ...value,
                id: value.value,
                disabled: !!field.id && !!formValues.type && !POSSIBLE_SCALAR_FIELD_TYPE_CONVERSIONS[formValues.type]?.includes(value.value)
            }]
        )) as ITreeStructure<IFieldType & INode>)
    }

    buildCompositeFieldTypesTree() {
        const {compositeFieldTypes} = this.props
        return TreeStructure.build(Object.fromEntries(compositeFieldTypes.map(value =>
            [value.value, {...value, id: value.value}]
        )) as ITreeStructure<IFieldType & INode>)
    }

    onArgumentsUpdate(customArguments: ICustomArguments) {
        this.setState({
            formValues: {
                ...this.state.formValues,
                arguments: customArguments
            }
        })
    }

    render() {
        const {resource, match, history, onFinish, field} = this.props
        const targetEntityOptions = this.getTargetEntityOptions();

        const formValues = {...this.state.formValues}

        const fields = resource.fields.sort((a, b) => (a.label || a.name) > (b.label || b.name) ? 1 : -1)
        const modeOptions = [
            {value: FIELD_MODE_SCALAR, label: 'Hodnota'},
            {value: FIELD_MODE_RELATION, label: 'Vazba'},
            {value: FIELD_MODE_COMPUTED, label: 'Vypočtená hodnota'},
            {value: FIELD_MODE_COMPOSITE, label: 'Složené pole'}
        ]

        return (
            <>
                <Modal
                    title={field?.label || 'Nové pole'}
                    visible={!!field}
                    width={800}
                    cancelText={'Zrušit'}
                    okText={'Potvrdit'}
                    onOk={this.onModalConfirm}
                    onCancel={() => onFinish()}
                    style={{overflow: "hidden"}}
                >
                    <Form initialValues={formValues} onValuesChange={this.onValuesChange} ref={this.refFieldForm}>
                        <Form.Item
                            name={"label"}
                            label={"Název"}
                            rules={[
                                {required: true, message: 'Název je povinný.'},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name={"name"}
                            label={"Kód"}
                            rules={[
                                {required: true, message: 'Kód je povinný.'},
                                () => ({
                                    validator(_, value) {
                                        if (value.match(/^[a-zA-Z_]*$/)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Kód může obsahovat jen malá písmena a podtržítka.'));
                                    }
                                }),
                                () => ({
                                    validator(_rule, name) {
                                        const collisionField = fields.find(f => f.name.replace('_', '').toLowerCase() === name.replace('_', '').toLowerCase())
                                        if (collisionField && (!field || collisionField.id !== field.id)) {
                                            return Promise.reject(new Error('Kód není v tom to typu obsahu unikátní.'));
                                        }
                                        if (!formValues.locked && SQLReservedWords.includes(name.toUpperCase())) {
                                            return Promise.reject(new Error('Zdá se, že tento název odpovídá vyhrazeným slovům SQL, zkuste použít jiný název, abyste předešli poškození databáze.'));
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <Input disabled={formValues.locked}/>
                        </Form.Item>
                        <Form.Item
                            name={"mode"}
                            label={"Typ"}
                            rules={[
                                {required: true, message: 'Typ je povinný.'},
                            ]}
                        >
                            <Select disabled={formValues.locked}>
                                {modeOptions.map(option => {
                                    const forbiddenChange = [FIELD_MODE_RELATION, FIELD_MODE_SCALAR]
                                    const disabled = !!field.id && !!field.mode && option.value !== field.mode &&
                                        forbiddenChange.includes(option.value) && forbiddenChange.includes(field.mode)
                                    return (
                                        <Select.Option value={option.value} key={option.value}
                                                       disabled={disabled}>
                                            {option.label}
                                            {disabled && <Typography.Text
                                                disabled={true}>{' - změna typu již není k dispozici kvůli konzistenci databáze, zvažte vytvoření nového pole'}</Typography.Text>}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        {formValues.mode && (
                            <Form.Item
                                name={"type"}
                                label={"Formát"}
                                help={field.id && formValues.type && formValues.mode === FIELD_MODE_RELATION ? 'změna formátu již není k dispozici kvůli konzistenci databáze' : undefined}
                                rules={[
                                    {required: true, message: 'Formát je povinný.'},
                                ]}
                            >
                                {[FIELD_MODE_SCALAR, FIELD_MODE_COMPUTED].includes(formValues.mode) ? (
                                    <TreeSelect
                                        showSearch
                                        style={{width: '100%'}}
                                        treeData={this.buildFieldTypesTree()}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        allowClear disabled={formValues.locked}
                                    />
                                ) : {
                                    [FIELD_MODE_RELATION]:
                                        <Select
                                            disabled={formValues.locked || field.id}>
                                            {this.getRelationTypes().map(t => (
                                                <Select.Option value={t.value} key={t.value} disabled={t.disabled}>
                                                    {t.label}
                                                </Select.Option>
                                            ))}
                                        </Select>,
                                    [FIELD_MODE_COMPOSITE]: <TreeSelect
                                        showSearch
                                        style={{width: '100%'}}
                                        treeData={this.buildCompositeFieldTypesTree() as any}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        allowClear disabled={formValues.locked}
                                    />
                                }[formValues.mode as string]}
                            </Form.Item>
                        )}
                        {[FIELD_MODE_SCALAR, FIELD_MODE_COMPUTED].includes(formValues.mode) && (
                            <Form.Item
                                name={"unit"}
                                label={"Jednotka"}>
                                <Input/>
                            </Form.Item>
                        )}
                        {(
                            {
                                [FIELD_MODE_RELATION]: (
                                    <>
                                        <Form.Item
                                            name={"targetEntity"}
                                            label={"Cíl"}
                                            rules={[
                                                {
                                                    required: formValues.mode === FIELD_MODE_RELATION,
                                                    message: 'Vyberte cíl.'
                                                },
                                            ]}
                                        >
                                            <Select showSearch filterOption={(input, option) => {
                                                return Utils.stringContains(option?.children, input)
                                            }
                                            } disabled={formValues.locked}>
                                                {targetEntityOptions.map(t => (
                                                    <Select.Option value={t.value} key={t.value} disabled={t.disabled}>
                                                        {t.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={"loadInCollection"}
                                            label={"Načítat v seznamu"}
                                            valuePropName={'checked'}
                                        >
                                            <Switch/>
                                        </Form.Item>
                                        <Form.Item
                                            name={"loadInResource"}
                                            label={"Načítat v detailu"}
                                            valuePropName={'checked'}
                                        >
                                            <Switch/>
                                        </Form.Item>
                                        {formValues['type'] === RELATION_FIELD_TYPE.ONE_TO_MANY &&
                                            <Form.Item
                                                name={"deleteRelated"}
                                                label={"Povolit mazání připojených záznamů"}
                                                valuePropName={'checked'}
                                            >
                                                <Switch/>
                                            </Form.Item>}

                                    </>
                                ),
                                [FIELD_MODE_COMPUTED]: (
                                    <>
                                        <Form.Item
                                            name={"script"}
                                            label={"Skript"}
                                            rules={[
                                                {
                                                    required: formValues.mode === FIELD_MODE_COMPUTED,
                                                    message: 'Vyberte cíl.'
                                                },
                                            ]}
                                        >
                                            <ScriptPicker/>
                                        </Form.Item>
                                        <ArgumentEditor
                                            arguments={formValues.arguments}
                                            onChange={(customArguments: ICustomArguments) => this.onArgumentsUpdate(customArguments)}
                                        />
                                    </>
                                )
                            }[formValues.mode as string])}
                        {formValues['type'] && (formValues['mode'] === FIELD_MODE_SCALAR || formValues['targetEntity']) && (
                            <>
                                <Form.Item label={'Výchozí hodnota'} name={'defaultValue'}>
                                    <FieldDefaultValuePicker field={formValues as IField} history={history} match={match}/>
                                </Form.Item>
                                <Form.Item label={'Výchozí hodnota závislá na jiném poli'} name={'selfBasedDefaultValue'}>
                                    <FieldPicker allowTypes={[formValues.type]} className={'min-w-150'}
                                                 serviceClassName={resource.fullClassName} output={'name'} mode={'tree'}
                                                 showDisabledTypes={true}/>
                                </Form.Item>
                            </>

                        )}
                        {formValues['type'] &&
                            <FieldOptionsEditor contentType={resource} options={formValues.options} history={history}
                                                fieldName={['options']} field={formValues as IField} match={match}/>
                        }
                    </Form>


                </Modal>
            </>
        )
    }

    getRelationTypes() {
        const {formValues} = this.state
        const {relationTypes} = this.props

        return relationTypes.map(type => {
            let label = type.label
            let disabled = false
            if (formValues.targetEntity && type.value === RELATION_FIELD_TYPE.ONE_TO_MANY && !isVirtual(formValues.targetEntity)) {
                label += ' nelze vybrat pevně zakódovanou entitu, použijte místo toho M:N'
                disabled = true
            }
            return {
                value: type.value,
                label,
                disabled
            }
        })
    }

    getTargetEntityOptions() {
        const {formValues} = this.state
        const repositoryServices = this.props.listRepositoryServices()
        return Object.entries(repositoryServices).map(([className, service]) => {
            let label = className
            let disabled = false
            if (service.getContentType) {
                label += ' - ' + service.getContentType().label
            }
            if (formValues.type === RELATION_FIELD_TYPE.ONE_TO_MANY && !isVirtual(className)) {
                label += ' nelze vybrat pevně zakódovanou entitu pro vztah 1:N, použijte místo toho M:N'
                disabled = true
            }
            return {
                label,
                value: className,
                disabled
            }
        });
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(store, className),
        listRepositoryServices: () => selectors.services.collectionList(store),
        scripts: store.setup.scripts,
    }
}

export default connect(mapStateToProps)(ContentTypeFieldModal)