import React, {RefObject} from 'react';
import {FormInstance, Select, TreeSelect} from "antd";
import {connect} from "react-redux";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import IField from "../../../model/interface/dataStorage/IField";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import Utils from "../../../utils";
import FieldsService from "../../../model/service/dataStorage/FieldsService";
import {IAppState} from "../../../redux/store";
import ReactDOMServer from "react-dom/server";
import ViewTableItemsSettings from "../../app/view/table/ViewTableItemsSettings";

export type FieldPickerMode = 'simple' | 'tree'

interface IProps {
    value?: string | string[]
    onChange?: (identifier?: string | string[], field?: IField | IField[]) => void
    className?: string,
    style?: React.CSSProperties,
    mode?: FieldPickerMode
    output: 'name' | 'uuid'
    depth: number
    allowTypes?: string[]
    showDisabledTypes?: boolean
    forbiddenTypes?: string[]
    disabled?: boolean
    serviceClassName: string | 'all'
    contentTypes: IContentType[]
    findServiceByClassName: (className: string) => IRepositoryService | null
    multiple?: boolean
    wrapperClassName?: string,
    namePrefix?: string,
    allowTargetEntity?: string[]
    includeIdentifierFields?: boolean
}

interface IState {
    formRef: RefObject<FormInstance>,
    showModal: boolean
}

class FieldPicker extends React.Component<IProps, IState> {

    static defaultProps = {
        depth: 2,
        mode: 'simple' as FieldPickerMode,
        multiple: false
    }


    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            showModal: false
        }
    }

    onPickerChoose = (identifier?: string | string[], field?: IField | IField[]) => {
        const {onChange, namePrefix} = this.props
        let output = identifier
        if (namePrefix) {
            if (Array.isArray(output)) {
                output = output.map(o => namePrefix + o)
            } else {
                output = output ? namePrefix + output : output
            }
        }
        onChange?.(output, field)
    }

    getTreeOptions = (fields: IField[], depth: number, prevField?: string) => {
        const {output, includeIdentifierFields} = this.props
        const options: { key: string, title: string, value: string, children?: any, field?: IField, disabled?: boolean}[] = []
        const prefix = (prevField ? (prevField + '.') : '')
        if (output === 'name' && includeIdentifierFields) {
            options.push({title: 'ID', value: prefix + '.id', key: prefix + '.id'})
            options.push({title: 'UUID', value: prefix + '.uuid', key: prefix + '.uuid'})
        }
        fields.forEach(field => {
            const key = output === "uuid" ? field.uuid : prefix + field.name
            switch (field.mode) {
                case("scalar"):
                    options.push({title: field.label || field.name, value: key, key: key, field: field, disabled: !this.isFieldAllowed(field) })
                    break;
                case("relation"):
                    let children
                    if (field.targetEntity && depth > 0) {
                        children = this.getTreeOptions(this.getFields(field.targetEntity), depth - 1, key)
                    }
                    options.push({title: field.label || field.name, value: key, key: key, children, field: field, disabled: !this.isFieldAllowed(field) })
                    break;
            }
        })
        return options
    }

    render() {
        const {
            className,
            style,
            disabled,
            value,
            mode,
            output,
            depth,
            multiple,
            wrapperClassName,
            namePrefix,
            serviceClassName,
            contentTypes
        } = this.props
        const fields = this.getFields(serviceClassName);

        let groupedFields = serviceClassName === 'all' ? contentTypes.map(contentType => (
            {name: contentType.name, label: contentType.label, fields: this.getFields(contentType.fullClassName)}
        )) : []
        const fieldOptions = this.getTreeOptions(fields, depth, '')

        let parsedValue = value
        if (multiple && !Array.isArray(parsedValue)) {
            parsedValue = parsedValue ? [parsedValue] : []
        } else if (!multiple && Array.isArray(parsedValue)) {
            parsedValue = parsedValue[0]
        }
        if (namePrefix) {
            parsedValue = Array.isArray(value) ?
                value.map(v => v.replace(namePrefix, '')) : value?.replace(namePrefix, '')
        }

        return (
            <div className={"route-picker " + (wrapperClassName || '')} style={style}>
                {{
                    simple:
                        <Select allowClear={true} disabled={disabled} className={className}
                                dropdownMatchSelectWidth={false}
                                mode={multiple ? "multiple" : undefined}
                                value={parsedValue}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    Utils.stringContains(ReactDOMServer.renderToString(option?.children), input)}
                                onChange={value => this.onPickerChoose(value, fields.find(f => f[output] === value))}>
                            {groupedFields.length ?
                                groupedFields.map(group => (
                                    <Select.OptGroup key={group.name} label={group.label}>
                                        {this.buildFieldOption(group.fields, output)}
                                    </Select.OptGroup>
                                ))
                                : this.buildFieldOption(fields, output)}
                        </Select>,
                    tree:
                        <TreeSelect
                            treeDefaultExpandedKeys={parsedValue ? Array.isArray(parsedValue) ? parsedValue : [parsedValue] : []}
                            value={parsedValue}
                            allowClear
                            showSearch={true}
                            filterTreeNode={(input, node) => Utils.stringContains(node?.title, input)}
                            multiple={!!multiple}
                            disabled={disabled}
                            className={className}
                            treeData={fieldOptions}
                            onChange={(value, node, extra) => {
                                this.onPickerChoose(value, extra?.triggerNode?.props?.field)
                            }}
                        />
                }[mode || 'simple']
                }
            </div>
        )
    }

    private buildFieldOption(fields: IField[], output: "name" | "uuid") {
        const {showDisabledTypes} = this.props
        return fields.filter(f => showDisabledTypes || this.isFieldAllowed(f)).map(field => (
            <Select.Option key={field.id} value={{uuid: field.uuid, name: field.name}[output]}
                           disabled={ViewTableItemsSettings.isFilterableDisabled(field)}>
                {field.label || field.name}
                {field.label && <div className={'ml-2 d-inline text-muted'}>{field.name}</div>}
            </Select.Option>
        ));
    }

    getFields(serviceClassName: string) {
        const {findServiceByClassName, showDisabledTypes} = this.props
        return FieldsService.sort(findServiceByClassName(serviceClassName)?.getFields() || [])
            .filter(f => showDisabledTypes || this.isFieldAllowed(f))
            .sort((a, b) => {
                const aLabel = (a.label || a.name).toLowerCase()
                const bLabel = (b.label || b.name).toLowerCase()
                return aLabel > bLabel ? 1 : (bLabel > aLabel ? -1 : 0)
            })
    }

    isFieldAllowed = (f: IField) => {
        const {allowTypes, allowTargetEntity, forbiddenTypes} = this.props
        return  (!(forbiddenTypes?.length) || !forbiddenTypes.includes(f.type))
            && (!(allowTypes?.length) || allowTypes.includes(f.type))
            && (!(allowTargetEntity?.length) || (f.targetEntity && allowTargetEntity.includes(f.targetEntity)))
    }
}

const mapStateToProps = (state: IAppState) => {
    const {contentTypes} = state.setup
    return {
        contentTypes,
        findServiceByClassName: (className: string) => selectors.services.findOneOrNullByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(FieldPicker)
