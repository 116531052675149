import React from "react"
import arrayMove from "array-move";
import {Button, Col, Form, Input, Row, Select, Typography} from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import IViewItem from "model/interface/dataStorage/view/IViewItem";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import IViewPivotTableAggregationItemSettings
    from "../../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableAggregationItemSettings";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import DragSortList, {DragSortListItem} from "../../../shared/list/DragsortList";
import {
    FIELD_MODE_RELATION,
    FIELD_NUMBER_TYPES, FIELD_STRING_TYPES, FIELD_TEXT_TYPES,
    FIELD_TYPE,
    RELATION_FIELD_TYPES
} from "../../../../model/interface/dataStorage/IField";
import Modal from "../../../shared/modal/Modal";
import {
    API_AGGREGATION_TYPE,
    API_AGGREGATION_TYPE_DATE,
    API_AGGREGATION_TYPE_LIST,
    API_AGGREGATION_TYPE_NUMBER
} from "../../../../model/constants/ApiConstant";
import MomentJsDateFormat from "../../../shared/input/MomentJsDateFormat";
import Utils from "../../../../utils";

interface IProps {
    findContentTypeByUuid: (uuid: string) => IContentType
    items: IViewPivotTableAggregationItemSettings[]
    onChange: (values: IViewPivotTableAggregationItemSettings[]) => void,
    viewUnit: IViewUnit
}

interface IState {
    selected?: IViewPivotTableAggregationItemSettings
}

const AGGREGATION_FIELD_TYPES = [...FIELD_NUMBER_TYPES, FIELD_TYPE.DATE_TIME, ...RELATION_FIELD_TYPES, ...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES]

class AggregationItemsSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        const {items} = this.props
        const sortedItems = arrayMove(items, oldIndex, newIndex)
        oldIndex !== newIndex && this.props.onChange(sortedItems)
    }

    onChange = (fieldUuid: string, itemUuid?: string) => {
        const {items} = this.props
        const item = items.find(i => i.uuid === itemUuid)
        this.setState({selected: item || {field: fieldUuid, type: '', uuid: Utils.uuid()}})
    }


    save = (values?: any) => {
        if (values) {
            const {selected} = this.state
            let items = [...this.props.items]
            const index = items.findIndex(i => i.field === selected?.field)
            if (selected && index > -1){
                items[index] = {...selected, ...values}
            } else {
                selected && items.push({...selected, ...values})
            }

            this.props.onChange(items)
        }
        this.setState({selected: undefined})
    }

    getField = (value: string, property: 'uuid' | 'name' = 'uuid') => {
        const contentType = this.getContentType();
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    getContentType() {
        const {findContentTypeByUuid, viewUnit} = this.props
        return findContentTypeByUuid(viewUnit.contentTypes[0]);
    }

    deleteItem = (item: IViewPivotTableAggregationItemSettings) => {
        let items = [...this.props.items]
        this.props.onChange(items.filter(i => i.uuid !== item.uuid))
    }

    render() {
        const {selected} = this.state
        const {viewUnit, items} = this.props
        let aggregationItems: (IViewItem & IViewPivotTableAggregationItemSettings)[] = [];
        items?.forEach(item => {
            const viewItem = viewUnit.items.find(viewItem => viewItem.field === item.field && viewItem.enabled)
            if (viewItem) {
                aggregationItems.push({...viewItem, ...item})
            }
        })

        const itemProps: DragSortListItem<IViewItem & IViewPivotTableAggregationItemSettings> = {
            render: (item, index, handle) => {
                const fieldObject = this.getField(item.field)
                const {name, label} = fieldObject
                const title = item.options?.title || label || name
                return <Row justify={'space-between'}>
                    <Row align={"middle"}>
                        {handle}
                        {title && <Typography.Text strong className={'mr-2'}>{title}</Typography.Text>}
                        {title && <Typography.Text disabled={true} className={'mr-2'}>
                            ({API_AGGREGATION_TYPE_LIST.find(t => item.type === t.value)?.label})
                        </Typography.Text>}
                    </Row>
                    <Row align={"middle"}>
                        <Col>
                            <Button size={"small"} type={'link'} onClick={() => this.onChange(item.field, item.uuid)}
                                    icon={<EditOutlined/>}/>
                        </Col>
                        <Col>
                            <Button size={"small"} danger type={'link'} onClick={() => this.deleteItem(item)}
                                    icon={<DeleteOutlined/>}/>
                        </Col>
                    </Row>
                </Row>
            },
            className: 'border p-1 mb-2 shadow-sm'
        }

        const selectedField = selected ? this.getField(selected.field) : undefined

        return (
            <>
                {selectedField && <Modal visible={!!selected} destroyOnClose={true} title={selectedField.label}
                                         footer={false} onCancel={() => this.save()}>
                    <Form onFinish={this.save} initialValues={{label: selectedField.label, ...selected}}>
                        <Form.Item label={"Název"} name={'label'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={"Typ aggregace"} name={'type'}
                                   rules={[{required: true, message: 'Pole je povinné'}]}>
                            <Select options={API_AGGREGATION_TYPE_LIST.filter(type => {
                                if (selectedField.type === FIELD_TYPE.DATE_TIME) {
                                    return API_AGGREGATION_TYPE_DATE.includes(type.value)
                                }
                                if (selectedField.mode === FIELD_MODE_RELATION) {
                                    return API_AGGREGATION_TYPE.LIST === type.value
                                }
                                if ([...FIELD_STRING_TYPES, ...FIELD_TEXT_TYPES].includes(selectedField.type)) {
                                    return API_AGGREGATION_TYPE.LIST === type.value
                                }
                                if (FIELD_NUMBER_TYPES.includes(selectedField.type)) {
                                    return API_AGGREGATION_TYPE_NUMBER.includes(type.value)
                                }
                                return true
                            })}/>
                        </Form.Item>
                        {selectedField.type === FIELD_TYPE.DATE_TIME && (
                            <Form.Item label={"Formát data"} name={'dateFormat'}
                                       rules={[{required: true, message: 'Pole je povinné'}]}>
                                <MomentJsDateFormat/>
                            </Form.Item>
                        )}
                        <Row justify={"end"}>
                            <Button htmlType={'submit'} icon={<SaveOutlined/>} type={"primary"}>Uložit</Button>
                        </Row>
                    </Form>
                </Modal>}
                <Select suffixIcon={<PlusOutlined/>} value={''} className={'w-100 mb-2'}
                        onChange={(value) => this.onChange(value as string)}>
                    {viewUnit.items
                        .filter(i => AGGREGATION_FIELD_TYPES.includes(this.getField(i.field).type))
                        .map(item => {
                            const field = this.getField(item.field, 'uuid')
                            return (
                                <Select.Option value={item.field} key={item.field}>
                                    {item.options?.title || field.label || field.name}
                                </Select.Option>
                            )
                        })}
                </Select>
                <DragSortList
                    item={itemProps}
                    lockAxis={"y"}
                    onSortEnd={this.onSortEnd}
                    useDragHandle
                    handle={{
                        render: () => <div className={"d-flex flex-column px-2"}>
                            <CaretUpOutlined/>
                            <CaretDownOutlined/>
                        </div>
                    }}
                    children={aggregationItems}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(AggregationItemsSettings)